import React, {useState, useEffect,Fragment } from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { DollarSign, TrendingUp, Calendar, Volume2, Phone, Pause, Coffee, Clock, Droplet, Users } from 'react-feather'
import {Container, Row, Col, Card, CardBody, CardHeader, ButtonGroup, Button, Table, CardFooter,Modal, ModalHeader, ModalBody } from 'reactstrap'
import ChartistGraph from 'react-chartist';
import { Line } from 'react-chartjs-2';
import { Map as LeafletMap, GeoJSON, Marker, Popup } from 'react-leaflet';
import WorldData from 'world-map-geojson';
import {topCardState} from './data'
import { roundedChart, roundedChartOptions, roundedChartListener, callChart, callChartOptions, callChartListener, smallChart, smallChartOptions, smallChartListener } from './chartsData/chartist'
import {lineChartData, lineChartOptions} from './chartsData/chartJs'
import tc1 from '../../assets/images/dashboard/sale-product-1.png'
import tc2 from '../../assets/images/dashboard/sale-product-2.png'
import tc3 from '../../assets/images/dashboard/sale-product-3.png'
import tc4 from '../../assets/images/dashboard/sale-product-4.png'
import tc5 from '../../assets/images/user/2.png'
import tc6 from '../../assets/images/user/3.jpg'
import tc7 from '../../assets/images/user/4.jpg'
import tc8 from '../../assets/images/user/5.jpg'
import u1 from '../../assets/images/dashboard/call-chat-1.png'
import u2 from '../../assets/images/dashboard/call-chat-2.png'
import wp from '../../assets/images/dashboard/work-plan.png'
import { toast } from 'react-toastify';
import {Loading,SalesByCategory,SalesOverview,Hours,Day,Week,Month,FromDate,ToDate,Profit,Edit,WorkPlan,TotalNewUser,TotalInvest,BounceDate,SessionDuartion,Live,ViewMoreReports,Session,OurBestSeller,GetStarted,Location,OurGrowth} from '../../constant'
import emailjs from 'emailjs-com'
import axios from 'axios'

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
const mic = new SpeechRecognition()
mic.continuous = true
mic.interimResults = true
mic.lang = 'es-MX'

const Default = () => {
  const [isListening, setIsListening] = useState(false)
  const [command, setCommand] = useState(null)
  const [savedNotes, setSavedNotes] = useState([])
  const sendEmail = (e) => {
    var commantDefault = "Isaías manda a Rául un recordatorio de que la junta del viernes será a las 9:00"

    var mailTo = command.substring(
      command.lastIndexOf("Isaías manda a ")+15, 
      command.lastIndexOf(" un recordatorio de que ")
    )
    var message = command.substring(
      command.lastIndexOf(" un recordatorio de que") + 1
    )
    var email = {
      name:mailTo,
      fromName:"Isaias",
      message:message,
      userEmail:() => chooseEmail(mailTo)
    }

    const chooseEmail = (user) => {
      switch(user){
        case"Rául":
          return "ryahuaca@b2b-negocios.com"
        case "Gabriel":
          return "grosales@b2b-negocios.com"
        case "Isaías":
          return "icabrera@b2b-negocios.com"
        default:
          return "ryahuaca@b2b-negocios.com"
      }
    }

    var my_form=document.createElement('FORM')
    my_form.name='myForm'
    my_form.method='POST'
    my_form.action='#'

    var my_tb=document.createElement('INPUT')
    my_tb.type='TEXT'
    my_tb.name='name'
    my_tb.value=email.name
    my_form.appendChild(my_tb)

    var my_tb=document.createElement('INPUT')
    my_tb.type='HIDDEN'
    my_tb.name='fromName'
    my_tb.value='Isaias'
    my_form.appendChild(my_tb)

    var my_tb=document.createElement('INPUT')
    my_tb.type='HIDDEN'
    my_tb.name='message'
    my_tb.value= email.message
    my_form.appendChild(my_tb)

    var my_tb=document.createElement('INPUT')
    my_tb.type='HIDDEN'
    my_tb.name='userEmail'
    my_tb.value=chooseEmail(email.name)
    my_form.appendChild(my_tb)

    document.body.appendChild(my_form)

  console.log("Sending Email",email)

  emailjs.sendForm("service_z1xigl6","template_2xauf1c",my_form,"user_6Yiy6j6dBtbUgO2vEhYPP")
  .then(res=>console.log(res)).catch(err=> console.log(err))
  }


  useEffect(()=>{
    handleListen()
  },[isListening])

  const handleListen = () =>{
    if(isListening){
      mic.start()
      mic.onend = () => {
        console.log("Continue...")
        mic.start()
      }
    }else{
      mic.stop()
      mic.onend=()=>{
        console.log("Stopped Mic on Click")
      }
    }

    mic.onstart = () =>{
      console.log("Mic is ON")
    }

    mic.onresult = (event) =>{
      console.log(event.results)
      const transcript = Array.from(event.results)
        .map(result => result[0])
        .map(result => result.transcript)
        .join('')
        console.log("Transcript",transcript)
        setCommand(transcript)
        mic.onerror = event =>{
          console.log(event.error)
        }
    }

  }

  const handleSaveNote = () => {
    setSavedNotes([...savedNotes,command])
    setCommand(null)
    sendEmail()
    setIsListening(false)
  }
  
  return (
    <Fragment>
      <BreadCrumb parent="Home" subparent="Dashboard" title="Default"/>
      <Container fluid={true}>
        <Row>
          <Col lg="12" className="xl-100">
            <Row>
              <Col xl="4" className="xl-50 box-col-12">
                <Card>
                  <CardBody>
                    <div className="call-chat-card text-center">
                      <div className="setting-dot">
                        <div className="setting-bg pull-right"><i className="fa fa-spin fa-cog"></i></div>
                      </div>
                      <div className="call-images">
                        <img className="img-fluid" src={u1} alt=""/>
                        <img className="img-fluid" src={u2} alt=""/></div>
                      <h5 className="f-w-600">{"11:36"}</h5>
                      <div className="call-chart-height">
                        <div className="call-chart">
                        <ChartistGraph data={callChart} listener={callChartListener} options={callChartOptions} type={'Bar'} />
                        </div>
                      </div>
                      <div className="call-chat-bottom">
                        <div className="text-center">
                          <div className="d-inline-block" onClick={handleSaveNote} disabled={!command}><Pause/></div>
                          {isListening?
                          <div className="d-inline-block" onClick={handleSaveNote}><a href="#!" className="bg-secondary call-receive"><Phone/></a></div>:
                          <div className="d-inline-block" onClick={()=>setIsListening(prevState => !prevState)}><a href="#!" className="bg-primary call-receive"><Phone/></a></div>}
                          <div className="d-inline-block" onClick={()=>setIsListening(prevState => !prevState)} disabled={!command}><Volume2/></div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="8" className="xl-100 box-col-12">
                <Card>
                  <CardHeader>
                    <h5>{"Speech to text"}</h5>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div style={{padding:"15px 50px"}}>
                      {isListening ?
                      <h6><b>{"Escuchando ..."}</b></h6>:
                      <h6><b>{"Listo para escuchar ..."}</b></h6>}
                      
                      <h6><b>{command}</b></h6>
                      <hr/>
                      <h4><b>{"Minuta de la junta"}</b></h4>
                      <br/>
                      {savedNotes.map((note,i) => {
                        return(
                          <h6 key={"note_"+i}>{note}</h6>
                        )
                      })}
                    </div>
                  </CardBody>
                  {/* <CardFooter className="sales-product-table-footer">
                    <div className="media"><a href="#!" className="btn btn-outline-light">{"Last Week"}<i className="fa fa-angle-double-right ml-2"></i></a>
                      <div className="media-body"><a href="#!" className="pull-right">{ViewMoreReports} </a></div>
                    </div>
                  </CardFooter> */}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
     
      </Fragment>
    
  )
}

export default Default
